














import type { PipelineShortResponse } from "@/api/Pipelines"
import { usePipelineStatus } from "@/composables/pipelineStatus"
import { useUser } from "@/store/user"

export default defineComponent({
  props: {
    color: String,
    pipelines: Array as () => PipelineShortResponse[],
  },
  setup() {
    const user = useUser()

    const { detailedStatus, pipelineStatus } = usePipelineStatus()

    return { detailedStatus, pipelineStatus, user }
  },
})
