import type { PipelineShortResponse } from "@/api/Pipelines"

type PipelineStatus = "manual" | "stopped" | "running"

export function usePipelineStatus() {
  function pipelineStatus(pipeline: PipelineShortResponse): PipelineStatus {
    if (pipeline.interval !== null) {
      if (pipeline.user && pipeline.enabled) {
        return "running"
      } else {
        return "stopped"
      }
    } else {
      return "manual"
    }
  }

  function detailedStatus(pipeline: PipelineShortResponse) {
    switch (true) {
      case !pipeline.enabled:
        return "The pipeline is currently disabled"
      case pipeline.interval === null:
        return `The pipeline needs to be executed manually, it'll run on the ${pipeline.workerQueue} queue`
      case !pipeline.user:
        return "The pipeline needs to be re-enabled"
      case pipeline.interval === 0:
        return `The pipeline is executed on every telemetry update on the ${pipeline.workerQueue} queue`
      default:
        return `The pipeline is executed every ${pipeline.interval} seconds on the ${pipeline.workerQueue} queue`
    }
  }

  return {
    detailedStatus,
    pipelineStatus,
  }
}
