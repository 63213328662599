














import type { PipelineShortResponse, ShareLevel } from "@/api/Pipelines"
import Pipelines from "@/api/Pipelines"
import Alert from "@/components/Alert.vue"
import PipelineCards from "@/components/PipelineCards.vue"
import { useStatus } from "@/composables/status"
import { useUser } from "@/store/user"

export default defineComponent({
  components: { Alert, PipelineCards },
  props: {
    shareLevel: {
      required: true,
      type: String as () => ShareLevel,
    },
  },
  setup(props) {
    const loading = ref(true)
    const { statusAlert, resetStatus, setStatus } = useStatus()
    const user = useUser()
    const page = ref(1)
    const pipelines = ref([] as PipelineShortResponse[])
    const totalPages = ref(1)
    const pageSize = 12
    async function fetchPipelines() {
      resetStatus()
      const response = await Pipelines.getPage(user.authConfig, page.value - 1, pageSize, props.shareLevel)
      if (response.data != null) {
        pipelines.value = response.data.data
        totalPages.value = response.data.totalPages
      } else {
        setStatus("error", response.error)
      }
      loading.value = false
    }

    watch(page, fetchPipelines)
    onMounted(fetchPipelines)
    return { loading, page, pipelines, statusAlert, totalPages }
  },
})
